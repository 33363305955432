<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1>
          Eigen
        </h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <form v-on:submit.prevent="" v-on:keyup.enter="submit">
          <v-text-field
              v-model="form.firstname"
              label="Voornaam"
              required
          />
          <v-text-field
              v-model="form.lastname"
              label="Achternaam"
              required
          />
          <v-text-field
              v-model="form.date_of_birth"
              label="Geboortedatum"
              required
          />
          <v-text-field
              v-model="form.organisation"
              label="Organisatie"
              disabled
          />
          <v-text-field
              v-model="form.team"
              label="Team"
              disabled
          />
          <v-text-field
              v-model="form.function"
              label="Funcie/Rol"
              required
          />
          <v-text-field
              v-model="form.email"
              label="Emailadres"
              required
          />
          <v-text-field
              v-model="form.password"
              label="Wachtwoord"
              type="password"
              required
          />
          <v-text-field
              v-model="form.password_confirmation"
              label="Wachtwoord controle"
              type="password"
              required
          />
          <v-btn color="accent" block @click="submit" :loading="loading" :disabled="loading">Opslaan</v-btn>
        </form>
      </v-col>

      <v-col cols="12" class="text-center">
        <h3>
          Account verwijderen
        </h3>
        <v-divider></v-divider>
        Je kan hier een aanvraag indienen om je account bij Humanwise te verwijderen. Deze aanvraag wordt op werkdagen binnen 48 uur in behandeling genomen.
      </v-col>

      <v-col cols="12" class="text-center">
        <v-btn color="red" @click="deleteAccount" :loading="loadingDelete" :disabled="loadingDelete" v-if="!this.user.account_removal_pending">Account verwijdering aanvragen</v-btn>
        <v-btn color="green" @click="deleteAccountStop" :loading="loadingDelete" :disabled="loadingDelete" v-if="this.user.account_removal_pending">Account verwijdering annuleren</v-btn>
      </v-col>
    </v-row>

    <snackbar-text :text="error.message" bg-color="red" text-color="white" v-model="error.show"/>
    <snackbar-text :text="success.message" bg-color="green" text-color="white" v-model="success.show" :button="false"/>
    <snackbar-text :text="accountDeleteSuccess.message" bg-color="green" text-color="white" v-model="accountDeleteSuccess.show" :button="false"/>
    <snackbar-text :text="accountDeleteStopSuccess.message" bg-color="green" text-color="white" v-model="accountDeleteStopSuccess.show" :button="false"/>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import apiClient from "@/services/apiClient";
import SnackbarText from "@/components/snackbar-text";
import store from "@/store/store";

export default {
  name: "dashboardHome",
  components: {SnackbarText},
  data: () => ({
    form: {
      firstname: null,
      lastname: null,
      date_of_birth: null,
      organisation: null,
      team: null,
      function: null,
      email: null,
      password: null,
      password_confirmation: null,
    },
    error: {
      show: false,
      message: ""
    },
    success: {
      show: false,
      message: "Je informatie is bijgewerkt!"
    },
    accountDeleteSuccess: {
      show: false,
      message: "Je account verwijdering is aangevraagd!"
    },
    accountDeleteStopSuccess: {
      show: false,
      message: "Je account verwijdering is ingetrokken!"
    },
    loading: true,
    loadingDelete: false,
  }),
  computed: {
    ...mapGetters({
      user: "user/user",
      scan: "user/scan"
    }),
  },
  mounted() {
    this.form.firstname = this.user.firstname;
    this.form.lastname = this.user.lastname;
    this.form.date_of_birth = this.user.date_of_birth;
    this.form.organisation = this.user.organisation;
    this.form.team = this.user.team;
    this.form.function = this.user.function;
    this.form.email = this.user.email;

    this.loading = false;
  },
  methods: {
    getFormData() {
      let formData = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        date_of_birth: this.form.date_of_birth,
        function: this.form.function,
        email: this.form.email,
      }

      if (this.form.password !== null) {
        formData.password = this.form.password;
        formData.password_confirmation = this.form.password_confirmation;
      }

      return formData;
    },
    submit() {
      this.loading = true;

      apiClient.put("client/update", this.getFormData()).then(response => {
        store.dispatch('user/attemptGetUser').then(reponse => {
          this.success.show = true;
          this.loading = false;

          setTimeout(() => {
            this.$router.push({name: "dashboardOwn"})
          }, 1500);

        }).catch(() => {
          this.error.message = "Je informatie is bijgewerkt! Maar er trad ook een onbekende fout op.";

          this.error.show = true;
          this.loading = false;
        });
      }).catch((error) => {
        if (error.data && error.data.message) {
          if (error.data.message === "'firstname' is required") {
            this.error.message = "Voornaam veld is verplicht.";
          } else if (error.data.message === "'lastname' is required") {
            this.error.message = "Achternaam veld is verplicht.";
          } else if (error.data.message === "'date_of_birth' is required") {
            this.error.message = "Geboortedatum veld is verplicht.";
          } else if (error.data.message === "'function' is required") {
            this.error.message = "Functie/Rol veld is verplicht.";
          } else if (error.data.message === "'email' must be an E-mail address") {
            this.error.message = "Dit is geen geldig emailadres.";
          } else if (error.data.message === "'password' must be minimal 8") {
            this.error.message = "Wachtwoord moet minimaal 8 tekens lang zijn.";
          } else if (error.data.message === "'password' is not valid") {
            this.error.message = "Wachtwoord of wachtwoord bevestigen is ongeldig of niet gelijk.";
          } else if (error.data.message === "email already exists in App\\Client table") {
            this.error.message = "Er is al een gebruiker met dit email adres.";
          } else {
            this.error.message = "Er is een onbekende fout opgetreden.";
          }

          this.error.show = true;
        }


        this.loading = false;
      });
    },
    deleteAccount() {
      this.loadingDelete = true;

      apiClient.put("client/switch_account_removal").then(response => {
        store.dispatch('user/attemptGetUser').then(reponse => {
          this.accountDeleteSuccess.show = true;
          this.loadingDelete = false;

          setTimeout(() => {
            this.accountDeleteSuccess.show = false;
          }, 1500);

        }).catch(() => {
          this.error.message = "Er ging iets fout!";

          this.error.show = true;
          this.loadingDelete = false;
        });
      });
    },
    deleteAccountStop() {
      this.loadingDelete = true;

      apiClient.put("client/switch_account_removal").then(response => {
        store.dispatch('user/attemptGetUser').then(reponse => {
          this.accountDeleteStopSuccess.show = true;
          this.loadingDelete = false;

          setTimeout(() => {
            this.accountDeleteStopSuccess.show = false;
          }, 1500);

        }).catch(() => {
          this.error.message = "Er ging iets fout!";

          this.error.show = true;
          this.loadingDelete = false;
        });
      });
    },
  }
};
</script>
